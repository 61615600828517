import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

export default class ProjectCover extends React.Component {
  render() {
    const { node } = this.props.project;
    return (
      <div>
        <Link to={node.fields.slug} key={node.frontmatter.title}>
          {node.frontmatter.cover ? (
            <Img fluid={node.frontmatter.cover.childImageSharp.thumbnail} />
          ) : (
            <img src="//placehold.it/500x500" />
          )}
          <h2 class="title is-4 mt-2">{node.frontmatter.title}</h2>
        </Link>
      </div>
    );
  }
}
