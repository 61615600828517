import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout.jsx";
import ProjectCover from "../components/ProjectCover/ProjectCover";
import config from "../../data/SiteConfig";
import { Container, Columns, Column, Section, Breadcrumb, BreadcrumbItem } from "bloomer";

class Index extends React.Component {
  render() {
    const projects = this.props.data.projects.edges;

    return (
      <Layout>
        <Helmet>
          <title>{config.siteTitle}</title>
        </Helmet>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>Projects</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <h1 class="title is-1">Projects</h1>
            <hr />
            <Columns isMobile isMultiline>
              {projects.map((node) => {
                return (
                  <Column
                    isSize={{ mobile: 6, tablet: 4, desktop: 4, widescreen: 3 }}
                  >
                    <ProjectCover project={node} />
                    <h2 class="title is-4">{node.title}</h2>
                  </Column>
                );
              })}
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ProjectsQuery {
    projects: allMarkdownRemark(
      limit: 9
      filter: { fields: { template: { eq: "projects" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            cover {
              childImageSharp {
                thumbnail: fluid(maxWidth: 300, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            tagline
            year
            repertoire {
              title
              composer
            }
          }
        }
      }
    }
  }
`;
